body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.follow-text {
  margin-right: 10px;
  font-size: 24px;
  font-family: "Josefin Sans", serif;
}

.separator {
  font-family: 'Josefin Sans';
  font-size: 42px;
  text-align: center;
  background: black;
  width: 400px;
  align-self: center;
  color: white;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 20px 0 10px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
}

.spinner {
  border: 2px solid #f3f3f3; /* Light gray */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: flex;
}

.whole-wrapper {
  background-color: #fdcd37;
  width: 100%;
}

.date-wrapper {
  display: flex;
      flex-direction: column;
      align-items: flex-end;
}

.plus-jakarta-sans-regular {
  font-family: "Plus Jakarta Sans", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.josefin-sans-regular {
  font-family: "Josefin Sans", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.main-left-side {
  z-index: 1;
  width: 100px;
  height: calc(100vh - 50px); 
  background-color: white;
  border-top-right-radius: 100px;
}

.main-right-side {
  z-index: 1;
  width: 100px;
  height: calc(100vh - 50px); 
  background-color: white;
  border-top-left-radius: 100px;
}

.main-fold {
  background-color: #fdcd37;
  padding: 0px 100px 100px 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  display: 'flex';
  flex-direction: 'row';
  width: 100%;
  text-align: center;
}

.main-title-about {
  font-family: "Josefin Sans", serif;
  font-size: 80px;
  padding-top: 50px;
  line-height: 80px;
}

.main-title {
  font-family: "Josefin Sans", serif;
  font-size: 80px;
  padding-top: 50px;
  line-height: 80px;
}

.tour-title {
  font-family: 'Josefin Sans';
  font-size: 42px;
  text-align: center;
  background: black;
  width: 400px;
  align-self: center;
  color: white;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 20px 0 10px;
}

.main-subtitle {
  font-family: "Gabarito", serif;
  font-size: 50px;
}

.main-description {
  font-family: "Plus Jakarta Sans", serif;
  font-weight: 700;
  font-size: 24px;
  margin: 0px 100px 50px;
  padding: 20px 40px 0px;
  border-radius: 50px;
  line-height: 40px;
  /* box-shadow: 20px 20px 1px black;
  border-width: 10px;
  border-color: black;
  border-style: solid; */
}

.main-action {
  font-family: "Plus Jakarta Sans", serif;
  font-weight: 700;
  font-size: 24px;
  margin: 0px 100px 50px;
  padding: 20px 40px 0px;
  border-radius: 50px;
  line-height: 40px;
  background-color: #00BFFF;
}

.main-buttons-wrapper {
  width: 450px;
  align-self: center;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.iphone-button {
  /* background-color: black;
  border: 3px solid #00BFFF;
  border-radius: 10px; */
  color: white;
  width: 300px;
}

.iphone-button img {
  width: 200px;
  object-fit: contain;
}

a {
  text-decoration: none;
}

.android-button {
  /* background-color: black;
  border: 3px solid #34d498;
  border-radius: 10px;
  padding: 20px 40px; */
  color: white;
  width: 300px;
}

.below-fold-one-wrapper-parent {
  width: 100%;
  background-color: white;
  margin-top: -50px;
  padding-top: 50px;
  position: relative;
}

.below-fold-one-wrapper-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1000px;
  margin: auto;
  background-color: white;
  position: relative;
  padding: 0px;
}

.below-fold-one-right-wrapper {
  width: 100px;
  background-color: #fdcd37;
  height: 550px;
}

.below-fold-one-right {
  width: 100%;
  background-color: white;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  height: 600px;
}

.below-fold-one-left-wrapper {
  width: 100px;
  background-color: #fdcd37;
  height: 550px;
}

.below-fold-one-wrapper-wrapper.separator .below-fold-one-left-wrapper {
  width: 100px;
  background-color: #fdcd37;
  height: 200px;
}

.below-fold-one-left {
  width: 100%;
  background-color: white;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  height: 600px;
}

.below-fold-one-wrapper-wrapper.separator .below-fold-one-left-wrapper .below-fold-one-left {
  width: 100%;
  background-color: white;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  height: 200px;
}

.below-fold-one-left-fold {
  width: 100%;
  background-color: white;
  border-top-right-radius: 100px;
  height: 600px;
}

.below-fold-one-right-fold {
  width: 100%;
  background-color: white;
  border-top-left-radius: 100px;
  height: 600px;
}

.below-fold-one-right-top {
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 100px;

  height: 600px;
}

.below-fold-one-left-top {
  width: 100%;
  background-color: white;
  border-bottom-right-radius: 100px;

  height: 600px;
}

.below-fold-one-wrapper {
  background-color: #fdcd37;
  width: 800px;
  height: 550px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
}

.about.below-fold-one-wrapper {
  background-color: #fdcd37;
  width: 800px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: unset;
}

.about-image img {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  object-fit: cover;

  border: 10px solid white;
}

.about-image {
  margin: auto;
}

.right-nav {
  margin-right: 100px 
}

.separator-50 {
  height: 50px;
  width: 100%;
}

.about-name {
  margin-top: 10px;
  font-size: 30px;
}

.about-title {
  margin-top: 10px;
  font-size: 24px;
}

.about-description-desc {
  font-size: 18px;
  padding: 40px;

  margin: 50px;
  margin-top: 20px;

  line-height: 30px;
  background-color: black;
  color: #cfc9c0;
  border-radius: 20px;
  text-align: justify;
}

.about-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.about-side {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.video-tour-wrapper {
  margin-top: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.separator .below-fold-one-wrapper {
  background-color: #fdcd37;
  width: 800px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.bunch-app-image {
  position: absolute;
  z-index: 100;
  border-radius: 30px;
  border: solid 10px black;
  margin: -80px auto 0;
  width: 300px;
}

.below-fold-one-img-wrapper {
  width: 320px;
  margin: 0 auto 0;
  position: relative;
}

.tour-not-started-over {
  background-color: black;
  opacity: .6;
  width: 300px;
  margin: 0 auto 0;
  z-index: 100000;
  position: absolute;
  z-index: 100;
  border-radius: 30px;
  border: solid 10px black;
  margin: -80px auto 0;
  height: 625px;
  left: 0px;
}

.image-arrow-left {
  margin: 200px 0 0 100px;
  color: black;
  font-size: 100px;
  cursor: pointer;
}

.image-arrow-right {
  margin: 200px 100px 0 0 ;
  color: black;
  font-size: 100px;
  cursor: pointer;
}

.arrow-disabled {
  color: gray;
}

.comment-bubble {
  position: absolute;
  width: 242px;
  z-index: 200;
  background-color: #fdcd37;
  background-color: white;
  box-shadow: 10px 10px 1px black;
  padding: 20px;
  border-radius: 20px;
  border: solid 10px black;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-family: "Josefin Sans", serif;
  font-family: "Plus Jakarta Sans", serif;
}

.start-bubble {
  width: 200px;
 top: 150px;
 left: calc(50% - 112px); 
 cursor: pointer;
 align-items: center;
 display: flex;
 flex-direction: column;
}

.second-panel {
  width: 100%;
  background-color: #E6EBFF;
  height: 600px;
}

.one-panel {
  width: calc(100% - 100px);
  margin: 0 50px;

  border-radius: 50px;
  display: flex;
  flex-direction: row;
  background-color: #d6dff8;
  justify-content: center;
  align-items: center;
  border: solid 20px #fdcd37;
}

.one-panel-title {
  font-family: "Josefin Sans", serif;
  font-size: 60px;
  padding: 0px 0 10px 40px;
  color: black;
}

.start-tour-text {
  margin-top: 10px;
  font-family: "Josefin Sans", serif;
  text-transform: uppercase;
}

.one-panel-description {
  font-family: "Plus Jakarta Sans", serif;
  font-size: 24px;
  line-height: 40px;
  margin: 0 40px;
  font-weight: 600;
}

.one-panel-content-wrapper {
  flex: 1;
}

.one-panel-image-wrapper {
  /* padding: 50px */
  
}

.one-panel-image {
  /* border-radius: 20px;
  border: inset 100px #40E0D0;; */

  overflow: hidden;
}

.one-panel-image img {
  width: 600px;
  object-fit: cover;
  height: 400px;
}

.left-image-borders {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 20px;
}

.right-image-borders {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 100px;
}

/* @media (max-width: 375px) { */
@media (max-width: 1182px) {

  .second.about-side {
    margin-top: 50px;
  }

  .about.main-fold {
   
  }

  .about-name {
    margin-top: 20px;
    font-size: 30px;
  }
  
  .about-title {
    margin-bottom: 40px;
    font-size: 24px;
  }

  .main-title-about {
    width: 80%;
    align-self: center;
  }

  .about-description-desc {
    font-size: 18px;
    padding: 20px;
  
    margin: 20px;
    margin-top:10px;
  
    line-height: 30px;
  }

  .about.below-fold-one-wrapper {
    background-color: #fdcd37;
    width: 90%;
    height: 200px;
    margin: 0 auto;
    display: flex;
    position: relative;
  }

  .right-nav {
    margin-right: 10px ;
    display: flex;
  }

  .right-nav a {
    margin-right: 10px;
    text-align: right;
  }

  .about-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .separator {
    font-family: 'Josefin Sans';
    font-size: 42px;
    text-align: center;
    background: black;
    max-width: 100%;
    width: 80%;
    align-self: center;
    color: white;
    border-radius: 10px;
    text-transform: uppercase;
    padding: 20px 0 10px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .one-panel-content-wrapper {
    flex: 1;
  }

  .mobile-only {
    display: flex;
  }

  .desktop-only {
    display: none;
  }

  .iphone-button {
    color: white;
    width: 300px;
    margin-bottom: 10px;
  }

  .iphone-button img {
    width: 220px;
    object-fit: contain;
  }

  .main-fold {
    background-color: #fdcd37;
    padding: 0px 10px 10px 10px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 0px;
  }

  .main-title {
    font-family: "Josefin Sans", serif;
    font-size: 60px;
    padding: 50px 40px 70px 40px;
    order: 1;
  }

  .main-description {
    font-family: "Plus Jakarta Sans", serif;
    font-weight: 500;
    font-size: 24px;
    margin: 0px 20px 50px;
    padding: 20px 20px 0px;
    border-radius: 50px;
    line-height: 40px;
    order: 3
  }

  .main-buttons-wrapper {
    width: 300px;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    order: 2;
    margin-bottom: 40px;
  }

  .main-buttons-wrapper a {
    align-content: center;
    display: flex;
    justify-content: center;
  }

  .below-fold-one-wrapper {
    background-color: #00BFFF;
    width: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    height: auto !important;
  }

  .image-arrow-left {
    margin: 100px 0 0 10px;
    color: black;
    font-size: 100px;
    cursor: pointer;
  }
  
  .image-arrow-right {
    margin: 100px 10px 0 0 ;
    color: black;
    font-size: 100px;
    cursor: pointer;
  }

  .below-fold-one-left-wrapper {
    display: none;
  }

  .below-fold-one-right-wrapper {
    display: none;
  }

  .below-fold-one-wrapper-parent {
    position: relative;
    width: 100%;
    background-color: #00BFFF;
    margin-top: 0px;
    padding-top: 0px;
    height: 470px;
  }

  .below-fold-one-wrapper-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: auto;
    background-color: blue;
    position: relative;
    padding: 0px;
  }

  .below-fold-one-wrapper-wrapper.separator {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: auto;
    background-color: white;
    position: relative;
    height: 200px;
    padding: 0px;
  }

  .below-fold-one-wrapper-wrapper.separator .below-fold-one-left-wrapper,
  .below-fold-one-wrapper-wrapper.separator .below-fold-one-right-wrapper {
    display: flex;
    width: 100px;
    height: 200px;
    padding: 0px;
  }

  .below-fold-one-wrapper-wrapper.separator .below-fold-one-left-wrapper .below-fold-one-left {
    width: 100%;
    background-color: white;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    height: 200px;
  }

  .separator .below-fold-one-wrapper {
    background-color: #fdcd37;
    width: calc(100% - 0px);
    margin: 0;
    padding: 0px 0;
    display: flex;
    position: relative;
    height: 200px !important;
  }

  .separator-big .below-fold-one-wrapper {
    height: 500px !important;
  }

  .separator-big .below-fold-one-wrapper-wrapper.separator .below-fold-one-left-wrapper .below-fold-one-left {
    height: 500px !important;
  }

  .separator-big .below-fold-one-wrapper-wrapper.separator .below-fold-one-left-wrapper .below-fold-one-left {
    height: 500px !important;
  }

  .separator-big .below-fold-one-wrapper-wrapper.separator .below-fold-one-left-wrapper .below-fold-one-right {
    height: 500px !important;
  }

  .below-fold-one-wrapper-wrapper.separator-big {
    height: 500px !important;
  }


  .bunch-app-image {
    position: absolute;
    z-index: 100;
    border-radius: 30px;
    border: solid 10px black;
    margin: 0px auto 0;
    width: 200px;
  }

  .below-fold-one-img-wrapper {
    width: 220px;
    margin: 0 auto 0;
    position: relative;
  }

  .tour-not-started-over {
    background-color: black;
    opacity: .6;
    width: 200px;
    margin: 0 auto 0;
    z-index: 100000;
    position: absolute;
    z-index: 100;
    border-radius: 30px;
    border: solid 10px black;
    margin: 0px auto 0;
    height: 408px;
    left: calc(50% - 100px);
  }

  .comment-bubble {
    position: absolute;
    width: 150px;
    z-index: 200;
    background-color: white;
    box-shadow: none;
    padding: 20px;
    border-radius: 10px;
    border: solid 5px black;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    font-family: "Josefin Sans", serif;
    font-family: "Plus Jakarta Sans", serif;

    top: 400px !important;
    right: 10px !important;
    left: unset !important;
  }

  .start-bubble {
    position: absolute !important;
    top: 55px  !important;
    left: calc(50% - 75px)!important;
    cursor: pointer;
    width: 150px;
   }

   .one-panel {
    width: 100%;
    padding: 0;
    margin: 0px 0px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    background-color: #d6dff8;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .one-panel-description {
    font-family: "Plus Jakarta Sans", serif;
    font-size: 20px;
    line-height: 40px;
    margin: 0 40px;
    font-weight: 500;
    text-align: center;
  }

  .one-panel-title {
    margin-top: 0px;
    font-family: "Josefin Sans", serif;
    font-size: 40px;
    padding: 40px 0 30px 0px;
    color: black;
    text-align: center;
  }

  .one-panel-image {
    order: 1 !important;
    overflow: hidden;
    height: 250px !important;
    border-radius: 0px !important;
    width: 90% !important;
  }

  .one-panel-image img {
    width: 100%;
    object-fit: contain;
    align-self: flex-start;
    height: 205px !important;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    border-top-left-radius: 20px;
    margin-top: 30px;

    height: auto !important;
  }

  .main-left-side {
    display: none;
  }
  
  .main-right-side {
    display: none;
  }

  .email-text {
    margin-bottom: 50px;
  }

  .below-fold-one-wrapper-parent,
  .below-fold-one-wrapper-wrapper {
    height: auto !important;
  }

  .below-fold-one-img-wrapper {
    height: 600px !important;
    width: 80% !important;
    padding: 0px;
    margin: 0px !important;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 400px) and (max-width: 1181px) {

  .one-panel-image {

    height: 300px !important;
    object-fit: contain !important;

  }

  .one-panel-image img {
    height: auto !important;
    object-fit: contain !important;

  }
}

@media (min-width: 910px) and (max-width: 1181px) {

  .comment-bubble {
    position: absolute;
    width: 150px;
    top: 40px !important;
    left: calc(50% - 350px) !important;
  }

}

@media (min-width: 830px) and (max-width: 910px) {

  .comment-bubble {
    position: absolute;
    width: 150px;
    top: 40px !important;
    left: 100px !important;
  }

}

@media (min-width: 400px) and (max-width: 830px) {

  .below-fold-one-img-wrapper {
    width: 220px;
  }

}

@media (max-width: 830px) {

  .tour-title {
    font-family: 'Josefin Sans';
    font-size: 22px;
    text-align: center;
    background: black;
    width: 200px;
    align-self: center;
    color: white;
    border-radius: 10px;
    text-transform: uppercase;
    padding: 15px 0 10px;
  }

  .below-fold-one-wrapper-parent {
    height: 506px;
  }

  .below-fold-one-img-wrapper {
    margin: 0 auto;
    position: relative;
    width: 200px;
  }

  .comment-bubble {
    background-color: #fff;
    border: 5px solid #000;
    border-radius: 10px;
    box-shadow: none;
    font-family: Josefin Sans, serif;
    font-family: Plus Jakarta Sans, serif;
    font-size: 16px;
    font-weight: 600;
    left: auto !important;
    line-height: 26px;
    padding: 20px;
    position: relative;
    right: unset !important;
    left: unset !important;
    text-align: center;
    top: unset !important;
    width: 70%;
    margin: 0 auto 30px;
    z-index: 200;
  }

  .start-bubble {
    top: 55px  !important;
    left: calc(50% - 75px)!important;
    cursor: pointer;
    width: 150px;
  }

}

